<script>
import Modal from './utilities/Modal'

export default {
  name: 'Terms',
  components: {
    Modal,
  },
  props: ['terms'],
  data() {
    return {
      showTerms: false,
    }
  },
}
</script>

<template>
  <div>
    <div class="cursor-pointer" @click="showTerms = true">
      <slot />
    </div>

    <ClientOnly>
      <Teleport to="#portal-destination">
        <Modal
          v-if="showTerms"
          key="sign-up-form"
          @close="showTerms = false"
        >
          <h3 class="text-sans font-bold text-2xl" v-html="$t('terms-and-conditions')" />
          <div class="pt-12" v-html="terms" />
        </Modal>
      </Teleport>
    </ClientOnly>
  </div>
</template>
